@if (!options?.noTitleBar) {
  <div
    class="lib-row lib-horizontal-space-between lib-vertical-center"
    [ngClass]="options?.titleClass || []"
    >
    @if (!options?.noTitle) {
      <h2 mat-dialog-title>
        {{ title }}
      </h2>
    }
    @if (!options?.hideCloseIcon) {
      <button
        mat-icon-button
        mat-dialog-close
        class="close-button"
        [ngClass]="$greyHover()"
        >
        <mat-icon> close</mat-icon>
      </button>
    }
  </div>
}
<div mat-dialog-content class="lib-fill">
  <ng-content></ng-content>
</div>
@if (actions && !options?.noAction) {
  <div mat-dialog-actions>
    <ng-container [ngTemplateOutlet]="actions"></ng-container>
  </div>
}
