import { PUBLIC_API_PATH } from 'src/app/_shared/_models/api.model';
import { LibHttp, LibHttpTypes } from 'src/lib/lib-http/models/lib-http.model';

import { User } from './user.model';

export const USER_DETAIL_HTTP_CONFIG: LibHttp = {
  url: `${PUBLIC_API_PATH}/user`,
  type: LibHttpTypes.GET,
};

export const USER_DEFAULT: User = {
  id: 0,
  username: '',
  isAdmin: false,
  permissionGroups: [],
  displayName: '',
  initials: '',
  email: '',
  corePath: '',
};
