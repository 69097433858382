import { Injectable, inject, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { DASHBOARD_MAIN_PATH } from 'src/app/dashboard/models/dashboard.model';
import { UserGroupsList } from './models/user-enums.model';
import { UserDataService } from './services/user-data.service';
import { UserGetService } from './services/user-get.service';
import { UserProcessService } from './services/user-process.service';
import { NavigateParams } from './models/user.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userData = inject(UserDataService);
  private userGet = inject(UserGetService);
  private userProcess = inject(UserProcessService);

  readonly $user = this.userData.$user.asReadonly();

  public $loadPage = signal<NavigateParams>({
    path: DASHBOARD_MAIN_PATH,
    queryParams: {},
  });

  public getUser$(apiPath: string): Observable<void> {
    return this.userGet.getUser$(apiPath);
  }

  public authenticate(groups: UserGroupsList[]): boolean {
    return this.userProcess.authenticate(groups);
  }

  public signOutButton(): void {
    const keys = this.azureLocalStorage();

    keys?.forEach((key) => {
      localStorage.removeItem(key);
    });

    location.reload();
  }

  private azureLocalStorage(): string[] {
    const storage = { ...localStorage };
    const keys = Object.keys(storage);
    return keys?.filter(
      (key) =>
        key.includes('login.windows.net') ||
        key.includes(environment.msalConfig.auth.clientId)
    );
  }
}
