import { Injectable } from '@angular/core';

import { LibDateTimeInMilliseconds } from '../models/lib-date.model';

@Injectable({
  providedIn: 'root',
})
export class LibDateTimeDifferenceService {
  public text(startDate: Date, endDate: Date = new Date()): string {
    const difference = this.differenceInMilliseconds(startDate, endDate);

    return this.getText(difference);
  }

  public differenceInMilliseconds(startDate: Date, endDate: Date): number {
    return startDate.valueOf() - endDate.valueOf();
  }

  private getText(difference: number): string {
    const preFix = difference > 0 ? 'in ' : '';
    const postFix = difference > 0 ? '' : ' ago';
    const absoluteDifference = Math.abs(difference);

    let text: string = '';

    switch (true) {
      case absoluteDifference <= 44 * LibDateTimeInMilliseconds.SECOND:
        text = preFix ? 'seconds' : 'a few seconds';
        break;
      case absoluteDifference <= 89 * LibDateTimeInMilliseconds.SECOND:
        text = 'a minute';
        break;
      case absoluteDifference <= 44 * LibDateTimeInMilliseconds.MINUTE:
        text = `${this.unitValue(
          absoluteDifference,
          LibDateTimeInMilliseconds.MINUTE
        )} minutes`;
        break;
      case absoluteDifference <= 89 * LibDateTimeInMilliseconds.MINUTE:
        text = 'an hour';
        break;
      case absoluteDifference <= 21 * LibDateTimeInMilliseconds.HOUR:
        text = `${this.unitValue(
          absoluteDifference,
          LibDateTimeInMilliseconds.HOUR
        )} hours`;
        break;
      case absoluteDifference <= 35 * LibDateTimeInMilliseconds.HOUR:
        text = 'a day';
        break;
      case absoluteDifference <= 25 * LibDateTimeInMilliseconds.DAY:
        text = `${this.unitValue(
          absoluteDifference,
          LibDateTimeInMilliseconds.DAY
        )} days`;
        break;
      case absoluteDifference <= 44 * LibDateTimeInMilliseconds.DAY:
        text = 'a month';
        break;
      case absoluteDifference <= 319 * LibDateTimeInMilliseconds.DAY:
        text = `${this.unitValue(
          absoluteDifference,
          LibDateTimeInMilliseconds.DAY * 30
        )} months`;
        break;
      case absoluteDifference <= 547 * LibDateTimeInMilliseconds.DAY:
        text = 'a year';
        break;
      default:
        text = `${this.unitValue(
          absoluteDifference,
          LibDateTimeInMilliseconds.DAY * 365
        )} years`;
        break;
    }

    return `${preFix}${text}${postFix}`;
  }

  private unitValue(
    milliseconds: number,
    unit: LibDateTimeInMilliseconds
  ): number {
    const remainder = milliseconds % unit;
    return (milliseconds - remainder) / unit;
  }
}
