<lib-dialog-shell [title]="title || ''" [actions]="actions" [options]="options">
  <ng-container
    [ngTemplateOutlet]="this[options?.displayType || 'plain']"
    [ngTemplateOutletContext]="{
      $implicit: message,
    }"
  >
  </ng-container>
</lib-dialog-shell>

<ng-template #actions>
  <div class="lib-horizontal-end lib-vertical-center lib-fill">
    @if (options?.showCancel) {
      <button color="warn" mat-raised-button [mat-dialog-close]="false">
        {{ options?.cancelButtonText || "Cancel" }}
      </button>
    }
    <button color="primary" mat-raised-button [mat-dialog-close]="true">
      {{ options?.buttonText || "OK" }}
    </button>
  </div>
</ng-template>

<ng-template #plain let-data>
  <ng-container>
    @for (text of data; track $index) {
      <div>{{ text }}</div>
    }
  </ng-container>
</ng-template>

<ng-template #html let-data>
  <ng-container>
    @for (text of data; track $index) {
      <div [innerHTML]="text"></div>
    }
  </ng-container>
</ng-template>

<ng-template #text let-data>
  <ng-container>
    @for (text of data; track $index) {
      <div [innerText]="text"></div>
    }
  </ng-container>
</ng-template>