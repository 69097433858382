import { Injectable } from '@angular/core';
import { LibDateParsed, LibDateTypes } from '../models/lib-date.model';

@Injectable({
  providedIn: 'root',
})
export class LibDateFormatService {
  public format(date: Date, format?: LibDateTypes): string | null {
    if (date instanceof Date) {
      const parsedDate = this.parseDate(date);
      switch (format) {
        case LibDateTypes.ISO:
          return this.iso(date);
        case LibDateTypes.ISO_NO_TIMEZONE:
          return this.isoNoTimezone(parsedDate);
        case LibDateTypes.ISO_DATE_ONLY:
          return this.isoDateOnly(parsedDate);
        case LibDateTypes.ISO_DATE_ONLY_UTC:
          return this.isoDateOnlyUtc(parsedDate);
        case LibDateTypes.PIPE_SHORT_FORM:
          return this.pipeShort(parsedDate);
        case LibDateTypes.STANDARD_DATE_DASH:
          return this.standardDateDash(parsedDate);
        case LibDateTypes.STANDARD_DATE_TIME:
          return this.standardDateTime(parsedDate);
        case LibDateTypes.STANDARD_DATE_TIME_DASH:
          return this.standardDateTimeDash(parsedDate);
        case LibDateTypes.YEAR_START_DATE:
          return this.yearStartDate(parsedDate);
        case LibDateTypes.YEAR_START_DATE_DASH:
          return this.yearStartDateDash(parsedDate);
        case LibDateTypes.LONG_DATE:
          return this.longDate(parsedDate);
        default:
          return this.standardDate(parsedDate);
      }
    } else {
      return null;
    }
  }

  public dateTimeToDate(date: Date | null): Date | null {
    if (date instanceof Date) {
      const parsedDate = this.parseDate(date);
      return new Date(this.isoDateOnly(parsedDate));
    } else {
      return null;
    }
  }

  private standardDate(parsedDate: LibDateParsed): string {
    return `${parsedDate.month}/${parsedDate.date}/${parsedDate.year}`;
  }

  private standardDateDash(parsedDate: LibDateParsed): string {
    return `${parsedDate.month}-${parsedDate.date}-${parsedDate.year}`;
  }

  private standardDateTime(parsedDate: LibDateParsed): string {
    return `${parsedDate.month}/${parsedDate.date}/${parsedDate.year} ${parsedDate.hours}:${parsedDate.minutes}:${parsedDate.seconds}`;
  }

  private standardDateTimeDash(parsedDate: LibDateParsed): string {
    return `${parsedDate.month}-${parsedDate.date}-${parsedDate.year} ${parsedDate.hours}:${parsedDate.minutes}:${parsedDate.seconds}`;
  }

  private iso(date: Date): string {
    return date.toISOString();
  }

  private isoNoTimezone(parsedDate: LibDateParsed): string {
    return `${parsedDate.year}-${parsedDate.month}-${parsedDate.date}T${parsedDate.hours}:${parsedDate.minutes}:${parsedDate.seconds}.${parsedDate.milliseconds}`;
  }

  private isoDateOnly(parsedDate: LibDateParsed): string {
    return `${parsedDate.year}-${parsedDate.month}-${parsedDate.date}T00:00:00`;
  }

  private isoDateOnlyUtc(parsedDate: LibDateParsed): string {
    return `${parsedDate.year}-${parsedDate.month}-${parsedDate.date}T00:00:00Z`;
  }

  private yearStartDate(parsedDate: LibDateParsed): string {
    return `${parsedDate.year}/${parsedDate.month}/${parsedDate.date}`;
  }

  private yearStartDateDash(parsedDate: LibDateParsed): string {
    return `${parsedDate.year}-${parsedDate.month}-${parsedDate.date}`;
  }
  private pipeShort(parsedDate: LibDateParsed): string {
    return `${parsedDate.month}/${parsedDate.date}/${parsedDate.year}, ${parsedDate.hour12}:${parsedDate.minutes} ${parsedDate.meridiem}`;
  }

  private longDate(parsedDate: LibDateParsed): string {
    return `${parsedDate.monthText} ${parsedDate.date}, ${parsedDate.year}, ${parsedDate.hour12}:${parsedDate.minutes} ${parsedDate.meridiem}`;
  }

  private parseDate(date: Date): LibDateParsed {
    const hours = date.getHours();
    return {
      year: date.getFullYear().toString(),
      month: this.addZero(date.getMonth() + 1),
      date: this.addZero(date.getDate()),
      hours: this.addZero(hours),
      minutes: this.addZero(date.getMinutes()),
      seconds: this.addZero(date.getSeconds()),
      milliseconds: this.addZero(date.getMilliseconds(), true),
      hour12: this.addZero(this.convertHour(hours)),
      meridiem: hours < 12 ? 'AM' : 'PM',
      day: this.dayOfTheWeek(date.getDay()),
      monthText: date.toLocaleString('en-US', { month: 'long' }),
    };
  }

  private convertHour(hour: number): number {
    switch (true) {
      case hour === 0:
        return 12;
      case hour > 12:
        return hour - 12;
      default:
        return hour;
    }
  }

  private dayOfTheWeek(day: number): string {
    switch (day) {
      case 0:
        return 'Sunday';
      case 1:
        return 'Monday';
      case 2:
        return 'Tuesday';
      case 3:
        return 'Wednesday';
      case 4:
        return 'Thursday';
      case 5:
        return 'Friday';
      case 6:
        return 'Saturday';
      default:
        return '';
    }
  }

  private addZero(value: number, isMilliseconds?: boolean): string {
    return `00${value}`.slice(isMilliseconds ? -3 : -2);
  }
}
