import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { LibDialogOkService } from './lib-dialog-ok/services/lib-dialog-ok.service';
import { LibDialogOptions } from './models/lib-dialog.model';

@Injectable({
  providedIn: 'root',
})
export class LibDialogService {
  private libDialogOk = inject(LibDialogOkService);

  public ok$(
    title: string,
    message: string | string[],
    options: LibDialogOptions = {}
  ): Observable<boolean> {
    return this.libDialogOk.ok$(title, message, options);
  }

  public error$(
    title: string,
    message: string | string[],
    options: LibDialogOptions = {}
  ): Observable<boolean> {
    return this.libDialogOk.error$(title, message, options);
  }
}
