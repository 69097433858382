import { HttpHeaders } from '@angular/common/http';
import {
  LibApiDataConverterTypes,
  LibApiDataOptions,
} from '../../lib-api-data/models/lib-api-data.model';

export enum LibHttpTypes {
  DELETE = 'delete',
  GET = 'get',
  PATCH = 'patch',
  POST = 'post',
  PUT = 'put',
}

export interface LibHttp {
  readonly url: string;
  readonly type?: LibHttpTypes;
  readonly data?: any;
  readonly httpOptions?: LibHttpOptions;
}

export interface LibHttpOptions {
  readonly headers: HttpHeaders;
}

export interface LibHttpOptionsSettings {
  readonly dataConvertSubmit?: LibApiDataConverterTypes;
  readonly dataConvertOptionsSubmit?: LibApiDataOptions;
  readonly dataConvert?: LibApiDataConverterTypes;
  readonly dataConvertOptions?: LibApiDataOptions;
  readonly snackBarMessage?: string | null;
  readonly requestInfo?: string;
  readonly notFoundValue?: any;
  readonly noErrorPopup?: boolean;
  readonly defaultError?: string;
  readonly fullErrorMessage?: boolean;
}
