import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
  inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { LibStylesGreyDataService } from '../../lib-styles/services/lib-styles-grey-data.service';
import { LibDialogOptions } from '../models/lib-dialog.model';

@Component({
  standalone: true,
  selector: 'lib-dialog-shell',
  templateUrl: './lib-dialog-shell.component.html',
  styleUrls: ['./lib-dialog-shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgTemplateOutlet,
    NgClass,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
  ],
})
export class LibDialogShellComponent {
  @Input() title!: string;
  @Input() actions?: TemplateRef<any>;
  @Input() options?: LibDialogOptions;
  public $greyHover = inject(LibStylesGreyDataService).$greyHover;
}
