export enum LibDateTimeInMilliseconds {
  MILLISECOND = 1,
  SECOND = 1000,
  MINUTE = 60000,
  HOUR = 3600000,
  DAY = 86400000,
  WEEK = 604800000,
}

export const ISO_DATE: RegExp =
  /^\d{4}-\d\d-\d\d[T ]\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i;

export enum LibDateTypes {
  ISO = 'iso',
  ISO_NO_TIMEZONE = 'isoNoTimezone',
  ISO_DATE_ONLY = 'isoDateOnly',
  ISO_DATE_ONLY_UTC = 'isoDateOnlyUtc',
  STANDARD_DATE = 'standardDate',
  STANDARD_DATE_DASH = 'standardDateDash',
  STANDARD_DATE_TIME = 'standardDateTime',
  STANDARD_DATE_TIME_DASH = 'standardDateTimeDash',
  YEAR_START_DATE = 'yearStartDate',
  YEAR_START_DATE_DASH = 'yearStartDateDash',
  PIPE_SHORT_FORM = 'pipeShortForm',
  LONG_DATE = 'longDate',
}

export interface LibDateParsed {
  readonly year: string;
  readonly month: string;
  readonly date: string;
  readonly hours: string;
  readonly minutes: string;
  readonly seconds: string;
  readonly milliseconds: string;
  readonly hour12: string;
  readonly meridiem: string;
  readonly day: string;
  readonly monthText: string;
}

export interface LibDateValues {
  readonly year: number;
  readonly month: number;
  readonly date: number;
  readonly hours: number;
  readonly minutes: number;
  readonly seconds: number;
}
