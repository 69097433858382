export const environment = {
  production: false,
  dashboardApi: 'https://api.dashboard.wiki/api',
  msalConfig: {
    auth: {
      clientId: 'cdbece5a-b4e9-40e0-a766-8b3148ad7209',
      authority:
        'https://login.microsoftonline.com/e87792c9-27e7-4980-8e6e-0f72b92b9f0a/',
    },
  },
  apiConfig: {
    scopes: ['user.read'],
    uri: 'https://graph.microsoft.com/v1.0/me',
  },
  dashboard: {
    scopes: ['api://cdbece5a-b4e9-40e0-a766-8b3148ad7209/user_access'],
    uri: 'api/',
  },
};