import { Injectable, inject } from '@angular/core';
import { UNIQUE } from 'src/lib/lib-models/lib-unique.model';
import { UserGroupsList } from '../models/user-enums.model';
import { User, UserDetailApi } from '../models/user.model';
import { UserDataService } from './user-data.service';

@Injectable({
  providedIn: 'root',
})
export class UserParseService {
  private $user = inject(UserDataService).$user;

  public setUser(details: UserDetailApi, apiPath: string): void {
    const permissionGroups = this.setPermissionGroups(details);
    const userData: User = {
      id: details.userId,
      username: `${details.firstName} ${details.lastName}`,
      isAdmin: permissionGroups.some((group) => group === UserGroupsList.ADMIN),
      permissionGroups: permissionGroups,
      initials: this.getInitials(details.firstName, details.lastName),
      displayName: `${details.firstName} ${details.lastName}`,
      email: details.email,
      corePath: apiPath,
    };

    this.$user.set(userData);
  }

  private setPermissionGroups(details: UserDetailApi): UserGroupsList[] {
    const userGroups: UserGroupsList[] = [
      ...details.groups.map((group) => group as UserGroupsList),
    ];

    return userGroups.filter(UNIQUE);
  }

  private getInitials(firstName: string, lastName: string): string {
    return `${firstName.slice(0, 1).toUpperCase()}${lastName
      .slice(0, 1)
      .toUpperCase()}`;
  }
}
