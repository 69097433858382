import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LibDateBusinessDaysService {
  public addBusinessDays(days: number, date: Date = new Date()): Date {
    const day = date.getDay();
    const workDate = new Date(date.valueOf());
    workDate.setDate(
      workDate.getDate() +
        days +
        (day === 6 ? 2 : +!day) +
        Math.floor((days - 1 + (day % 6 || 1)) / 5) * 2
    );
    return workDate;
  }
}
