import { Injectable } from '@angular/core';
import {
  LibDateValues,
  LibDateTypes,
  ISO_DATE,
} from '../models/lib-date.model';

@Injectable({
  providedIn: 'root',
})
export class LibDateConvertService {
  public convert(dateString: string, format?: LibDateTypes): Date | null {
    switch (format) {
      case LibDateTypes.ISO:
        return ISO_DATE.test(dateString) ? new Date(dateString) : null;
      case LibDateTypes.STANDARD_DATE_DASH:
        return this.standardDateDash(dateString);
      case LibDateTypes.STANDARD_DATE_TIME:
        return this.standardDateTime(dateString);
      case LibDateTypes.STANDARD_DATE_TIME_DASH:
        return this.standardDateTimeDash(dateString);
      case LibDateTypes.YEAR_START_DATE:
        return this.yearStartDate(dateString);
      case LibDateTypes.YEAR_START_DATE_DASH:
        return this.yearStartDateDash(dateString);
      case LibDateTypes.STANDARD_DATE:
      default:
        return this.standardDate(dateString);
    }
  }

  private standardDateDash(dateString: string): Date | null {
    const splitValues = dateString?.split('-');
    return splitValues?.length === 3
      ? this.createDate({
          year: Number(splitValues?.[2]),
          month: Number(splitValues?.[0]),
          date: Number(splitValues?.[1]),
          hours: 0,
          minutes: 0,
          seconds: 0,
        })
      : null;
  }

  private standardDate(dateString: string): Date | null {
    const splitValues = dateString?.split('/');
    return splitValues?.length === 3
      ? this.createDate({
          year: Number(splitValues?.[2]),
          month: Number(splitValues?.[0]),
          date: Number(splitValues?.[1]),
          hours: 0,
          minutes: 0,
          seconds: 0,
        })
      : null;
  }

  private yearStartDateDash(dateString: string): Date | null {
    const splitValues = dateString?.split('-');
    return splitValues?.length === 3
      ? this.createDate({
          year: Number(splitValues?.[0]),
          month: Number(splitValues?.[1]),
          date: Number(splitValues?.[2]),
          hours: 0,
          minutes: 0,
          seconds: 0,
        })
      : null;
  }

  private yearStartDate(dateString: string): Date | null {
    const splitValues = dateString?.split('/');
    return splitValues?.length === 3
      ? this.createDate({
          year: Number(splitValues?.[0]),
          month: Number(splitValues?.[1]),
          date: Number(splitValues?.[2]),
          hours: 0,
          minutes: 0,
          seconds: 0,
        })
      : null;
  }

  private standardDateTimeDash(dateString: string): Date | null {
    const splitValues = dateString?.split(' ');
    const dateValues = splitValues?.[0]?.split('-');
    const timeValues = splitValues?.[1]?.split(':');
    return splitValues?.length === 2 &&
      dateValues?.length === 3 &&
      timeValues?.length === 3
      ? this.createDate({
          year: Number(dateValues?.[2]),
          month: Number(dateValues?.[0]),
          date: Number(dateValues?.[1]),
          hours: Number(timeValues?.[0]),
          minutes: Number(timeValues?.[1]),
          seconds: Number(timeValues?.[2]),
        })
      : null;
  }

  private standardDateTime(dateString: string): Date | null {
    const splitValues = dateString?.split(' ');
    const dateValues = splitValues?.[0]?.split('/');
    const timeValues = splitValues?.[1]?.split(':');
    return splitValues?.length === 2 &&
      dateValues?.length === 3 &&
      timeValues?.length === 3
      ? this.createDate({
          year: Number(dateValues?.[2]),
          month: Number(dateValues?.[0]),
          date: Number(dateValues?.[1]),
          hours: Number(timeValues?.[0]),
          minutes: Number(timeValues?.[1]),
          seconds: Number(timeValues?.[2]),
        })
      : null;
  }
  private createDate(libDatePValues: LibDateValues): Date | null {
    switch (true) {
      case libDatePValues?.hours > 23 ||
        libDatePValues?.hours < 0 ||
        libDatePValues?.hours % 1 !== 0:
      case libDatePValues?.month > 12 ||
        libDatePValues?.month < 1 ||
        libDatePValues?.hours % 1 !== 0:
      case libDatePValues?.year % 1 !== 0:
      case libDatePValues?.minutes > 59 ||
        libDatePValues?.minutes < 0 ||
        libDatePValues?.minutes % 1 !== 0:
      case libDatePValues?.seconds > 59 ||
        libDatePValues?.seconds < 0 ||
        libDatePValues?.seconds % 1 !== 0:
      case libDatePValues?.date < 0 ||
        libDatePValues?.date > 31 ||
        libDatePValues?.date % 1 !== 0:
      case [4, 6, 9, 11].includes(libDatePValues?.month) &&
        libDatePValues?.date > 30:
      case libDatePValues?.month === 2 &&
        libDatePValues?.year % 4 === 0 &&
        libDatePValues?.date > 29:
      case libDatePValues?.month === 2 &&
        libDatePValues?.year % 4 !== 0 &&
        libDatePValues?.date > 28:
        return null;
      default:
        return new Date(
          libDatePValues.year,
          libDatePValues.month - 1,
          libDatePValues.date,
          libDatePValues.hours,
          libDatePValues.minutes,
          libDatePValues.seconds
        );
    }
  }
}
