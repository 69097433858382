import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';

import { LibHttp } from 'src/lib/lib-http/models/lib-http.model';
import { LibHttpService } from 'src/lib/lib-http/lib-http.service';

import { UserDetailApi } from '../models/user.model';
import { UserParseService } from './user-parse.service';
import { USER_DETAIL_HTTP_CONFIG } from '../models/user-config.model';

@Injectable({
  providedIn: 'root',
})
export class UserGetService {
  private userParse = inject(UserParseService);
  private libHttp = inject(LibHttpService);

  public getUser$(apiPath: string): Observable<void> {
    return this.details$(apiPath).pipe(
      map((details) => this.userParse.setUser(details, apiPath)),
    );
  }

  public details$(apiPath: string): Observable<UserDetailApi> {
    const httpData: LibHttp = {
      ...USER_DETAIL_HTTP_CONFIG,
      url: `${apiPath}${USER_DETAIL_HTTP_CONFIG.url}`,
    };

    return this.libHttp.http$(httpData);
  }
}
