import { Injectable, effect, signal } from '@angular/core';
import { LibHttpOptionsSettings } from '../../lib-http/models/lib-http.model';
import { LibStyleFrameworkClasses } from '../../lib-styles/models/lib-styles-framework-enum.model';
import { LibFrameworkAppsList } from '../components/lib-framework-apps/models/lib-framework-apps.model';
import { LibFrameworkNotification } from '../components/lib-framework-notification/models/lib-framework-notification.model';
import {
  LibFrameworkDarkModeValue,
  LibFrameworkLocalStorage,
} from '../models/lib-framework-fields-enum.model';
import {
  LibFrameworkInfoSideBarType,
  LibFrameworkMenu,
  LibFrameworkOptions,
  LibFrameworkUser,
  LibFrameworkUserPanel,
} from '../models/lib-framework.model';

@Injectable({
  providedIn: 'root',
})
export class LibFrameworkDataService {
  public $menuNavOpened = signal<boolean>(false);
  public $infoNavOpened = signal<boolean>(false);
  public $menus = signal<LibFrameworkMenu[]>([]);
  public $options = signal<LibFrameworkOptions>({});
  public $userPanel = signal<LibFrameworkUserPanel>({});
  public $user = signal<LibFrameworkUser>({});
  public $notifications = signal<LibFrameworkNotification[]>([]);
  public $appList = signal<LibFrameworkAppsList[]>([]);
  public $routeName = signal<string>('');
  public $navSideBar = signal<LibFrameworkInfoSideBarType>(
    LibFrameworkInfoSideBarType.NONE,
  );
  public $isDarkMode = signal<boolean>(
    localStorage.getItem(LibFrameworkLocalStorage.DARK_MODE) ===
      LibFrameworkDarkModeValue.ON
      ? true
      : false,
  );
  public $hideRoute = signal<boolean>(false);

  public $currentPath = signal<string>('');
  public $globalHttpOptionSetting = signal<LibHttpOptionsSettings | null>(null);

  private darkModeEffect = effect(() => {
    if (this.$isDarkMode()) {
      document.body.classList.add(LibStyleFrameworkClasses.DARK_MODE);
      localStorage.setItem(
        LibFrameworkLocalStorage.DARK_MODE,
        LibFrameworkDarkModeValue.ON,
      );
    } else {
      document.body.classList.remove(LibStyleFrameworkClasses.DARK_MODE);
      localStorage.setItem(
      LibFrameworkLocalStorage.DARK_MODE,
      LibFrameworkDarkModeValue.OFF,
      );
    }
  });
}