import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  ChangeDetectorRef,
  inject,
} from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { LibDialogOptions } from '../models/lib-dialog.model';
import { NgTemplateOutlet } from '@angular/common';
import { LibDialogShellComponent } from '../lib-dialog-shell/lib-dialog-shell.component';
import { MatButtonModule } from '@angular/material/button';
@Component({
  standalone: true,
  selector: 'lib-dialog-ok',
  templateUrl: './lib-dialog-ok.component.html',
  styleUrls: ['./lib-dialog-ok.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgTemplateOutlet,
    MatDialogModule,
    MatButtonModule,
    LibDialogShellComponent
],
})
export class LibDialogOkComponent {
  @ViewChild('plain') plain!: TemplateRef<any>;
  @ViewChild('text') text!: TemplateRef<any>;
  @ViewChild('html') html!: TemplateRef<any>;

  public title?: string;
  public message?: string[];
  public options?: LibDialogOptions;

  public dialogRef = inject(MatDialogRef<LibDialogOkComponent>);
  private changeDetector = inject(ChangeDetectorRef);

  ngAfterViewInit(): void {
    this.changeDetector.detectChanges();
  }
}
