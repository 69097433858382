import { Injectable, inject } from '@angular/core';
import { UserGroupsList } from '../models/user-enums.model';
import { UserDataService } from './user-data.service';

@Injectable({
  providedIn: 'root',
})
export class UserProcessService {
  private user = inject(UserDataService).$user;

  public authenticate(groups: UserGroupsList[]): boolean {
    return (
      !groups ||
      groups.length === 0 ||
      groups.some((group) => this.user().permissionGroups.includes(group))
    );
  }
}
