import { Injectable, inject } from '@angular/core';

import {
  LibApiDataConverterTypes,
  LibApiDataOptions,
} from './models/lib-api-data.model';
import { LibApiDataConverterService } from './services/lib-api-data-converter.service';

@Injectable({
  providedIn: 'root',
})
export class LibApiDataService {
  private libApiDataConverter = inject(LibApiDataConverterService);

  public convert<T>(
    data: any,
    type: LibApiDataConverterTypes,
    options?: LibApiDataOptions
  ): T {
    return this.libApiDataConverter.convert<T>(data, type, options);
  }
}
