import { Injectable, signal } from '@angular/core';
import { User } from '../models/user.model';
import { USER_DEFAULT } from '../models/user-config.model';

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  public $user = signal<User>(USER_DEFAULT);
}
