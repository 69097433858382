import { LibFrameworkAppsList } from '../components/lib-framework-apps/models/lib-framework-apps.model';
import { LibFrameworkNotification } from '../components/lib-framework-notification/models/lib-framework-notification.model';

export enum LibFrameworkMenuType {
  ROUTE = 'route',
  LINK = 'link',
}

export enum LibFrameworkInfoSideBarType {
  NOTIFICATION = 'notification',
  USER = 'user',
  APPS = 'apps',
  NONE = 'none',
}
export interface LibFrameworkMenu {
  readonly name: string;
  readonly subMenus: LibFrameworkSubMenu[];
  readonly singleMenu?: boolean;
  readonly toggle?: boolean;
  readonly icon?: string;
}
export interface LibFrameworkSubMenu {
  readonly name?: string;
  readonly path?: string;
  readonly groups?: string[];
  readonly type?: LibFrameworkMenuType;
  readonly active?: boolean;
  readonly icon?: string;
}

export interface LibFrameworkOptions {
  readonly title?: string;
  readonly version?: string;
  readonly devMessage?: string;
  readonly showUserIcon?: boolean;
  readonly showNotificationIcon?: boolean;
  readonly showAppIcon?: boolean;
  readonly menuIcons?: boolean;
}

export interface LibFrameworkUser {
  readonly initials?: string;
  readonly groups?: string[];
  readonly icon?: string;
}

export interface LibFrameworkUserPanel {
  readonly topButtons?: LibFrameworkUserPanelTopButton[];
}

export interface LibFrameworkUserPanelTopButton {
  readonly icon: string;
  readonly name: string;
}
export interface LibFrameworkState {
  readonly menuNavOpened: boolean;
  readonly infoNavOpened: boolean;
  readonly libFrameworkMenus: LibFrameworkMenu[];
  readonly libFrameworkOptions: LibFrameworkOptions;
  readonly libFrameworkUser: LibFrameworkUser;
  readonly libFrameworkNotifications: LibFrameworkNotification[];
  readonly libFrameworkApps: LibFrameworkAppsList[];
  readonly libFrameworkUserPanel: LibFrameworkUserPanel;
}