export enum LibStyleFrameworkClasses {
  LIB_FONT_SIZE = 'lib-font-size',
  LIB_PRIMARY_COLOR = 'lib-primary-color',
  LIB_PRIMARY_HOVER = 'lib-primary-hover',
  LIB_PRIMARY_COLOR_TEXT = 'lib-primary-text',
  LIB_ACCENT_COLOR = 'lib-accent-color',
  LIB_ACCENT_HOVER = 'lib-accent-hover',
  LIB_ACCENT_TEXT = 'lib-accent-text',
  LIB_WARN_COLOR = 'lib-warn-color',
  LIB_WARN_TEXT = 'lib-warn-text',
  LIB_GREY_COLOR = 'lib-grey-color',
  LIB_GREY_HOVER = 'lib-grey-hover',
  LIB_GREY_COLOR_DARK = 'lib-grey-color-dark',
  LIB_GREY_HOVER_DARK = 'lib-grey-hover-dark',
  DARK_MODE = 'dark-mode',
}
