import { Injectable, inject } from '@angular/core';

import { LibDateService } from 'src/lib/lib-date/lib-date.service';
import { ISO_DATE, LibDateTypes } from 'src/lib/lib-date/models/lib-date.model';
import {
  LibApiDataConverterTypes,
  LibApiDataOptions,
} from '../models/lib-api-data.model';

@Injectable({
  providedIn: 'root',
})
export class LibApiDataConverterService {
  private isoDate = ISO_DATE;
  private libDate = inject(LibDateService);

  public convert<T>(
    data: any,
    type?: LibApiDataConverterTypes,
    options?: LibApiDataOptions
  ): T {
    switch (true) {
      case !data:
        return data ?? null;
      case data instanceof Array:
        return this.convertArray(data, type, options);
      case typeof data === 'object':
        return this.convertObject(data, type, options);
      default:
        return this.convertDate(data, options);
    }
  }

  private convertArray(
    data: any,
    type?: LibApiDataConverterTypes,
    options?: LibApiDataOptions
  ): any {
    return data.map((value: any) => {
      if (value instanceof Array || typeof value === 'object') {
        return this.convert(value, type, options);
      }
      return this.convertDate(value, options);
    });
  }

  private convertObject(
    data: any,
    type?: LibApiDataConverterTypes,
    options?: LibApiDataOptions
  ): any {
    let convertedData: any = {};
    const objectKeys = Object.keys(data);

    if (!objectKeys.length) {
      return data;
    }

    objectKeys.forEach((key) => {
      const convertedKey = this.convertedKey(key, type);

      const value = data[key];
      if (value instanceof Array || value?.constructor === Object) {
        convertedData[convertedKey] = this.convert(value, type, options);
      } else {
        convertedData[convertedKey] = this.convertDate(
          value,
          options,
          key,
          convertedKey
        );
      }
    });
    return convertedData;
  }

  private convertedKey(key: string, type?: LibApiDataConverterTypes): string {
    switch (type) {
      case LibApiDataConverterTypes.TO_CAMEL:
        return this.convertToCamel(key);
      case LibApiDataConverterTypes.UNDERSCORE_TO_CAMEL:
        return this.convertUnderscoreToCamel(key);
      case LibApiDataConverterTypes.CAMEL_TO_UNDERSCORE:
        return this.convertCamelToUnderscore(key);
      default:
        return key;
    }
  }

  private convertToCamel(key: string): string {
    return `${key.charAt(0).toLowerCase()}${key.slice(1)}`;
  }

  private convertUnderscoreToCamel(key: string): string {
    const newKey = key
      .split('_')
      .map(
        (record) =>
          `${record.charAt(0).toUpperCase()}${record.slice(1).toLowerCase()}`
      )
      .join('');

    return this.convertToCamel(newKey);
  }

  private convertCamelToUnderscore(key: string): string {
    return key
      .split(/(?=[A-Z])/)
      .map((record) => record.toLowerCase())
      .join('_');
  }

  private convertDate(
    value: any,
    options?: LibApiDataOptions,
    key?: string,
    convertedKey?: string
  ): any {
    switch (true) {
      case options?.noTimezoneDateConversion && value instanceof Date:
        return this.libDate.dateToString(value, LibDateTypes.ISO_NO_TIMEZONE);
      case this.matchKeys(options?.dateOnlyObjectKeys, key, convertedKey) &&
        value instanceof Date:
        return this.libDate.dateToString(
          value,
          options?.useUtc
            ? LibDateTypes.ISO_DATE_ONLY_UTC
            : LibDateTypes.ISO_DATE_ONLY
        );
      case key && convertedKey && options?.dateOnly && this.isoDate.test(value):
      case this.matchKeys(options?.dateOnlyObjectKeys, key, convertedKey) &&
        this.isoDate.test(value):
        return this.isoStripTime(value, options);
      case !options?.noIsoConversion && this.isoDate.test(value):
        return new Date(value);
      default:
        return value ?? null;
    }
  }

  private isoStripTime(isoDate: string, options?: LibApiDataOptions): Date {
    if (options?.useUtc) {
      const localeDate = new Date(isoDate);

      return new Date(
        localeDate.getUTCFullYear(),
        localeDate.getUTCMonth(),
        localeDate.getUTCDate()
      );
    } else {
      const isoSplit = isoDate.split('T');
      return new Date(`${isoSplit[0]}T00:00:00`);
    }
  }

  private matchKeys(
    dateOnlyObjectKeys: string[] = [],
    key: string = '',
    convertedKey: string = ''
  ): boolean {
    return !!(
      key &&
      convertedKey &&
      (dateOnlyObjectKeys.includes(key) ||
        dateOnlyObjectKeys.includes(convertedKey))
    );
  }
}
