import { Injectable, inject } from '@angular/core';

import { LibDateTypes } from './models/lib-date.model';
import { LibDateBusinessDaysService } from './services/lib-date-business-days.service';
import { LibDateConvertService } from './services/lib-date-convert.service';
import { LibDateFormatService } from './services/lib-date-format.service';
import { LibDateTimeDifferenceService } from './services/lib-date-time-difference.service';

@Injectable({
  providedIn: 'root',
})
export class LibDateService {
  private libDateBusinessDays = inject(LibDateBusinessDaysService);
  private libDateFormat = inject(LibDateFormatService);
  private libDateTimeDifference = inject(LibDateTimeDifferenceService);
  private libDateConvert = inject(LibDateConvertService);

  public addBusinessDays(days: number, date: Date = new Date()): Date | null {
    return this.libDateBusinessDays.addBusinessDays(days, date);
  }

  public dateToString(date: Date, format?: LibDateTypes): string | null {
    return this.libDateFormat.format(date, format);
  }

  public stringToDate(dateString: string, format?: LibDateTypes): Date | null {
    return this.libDateConvert.convert(dateString, format);
  }

  public textDifference(
    startDate: Date = new Date(),
    endDate: Date = new Date()
  ): string {
    return this.libDateTimeDifference.text(startDate, endDate);
  }

  public dateTimeToDate(date: Date | null): Date | null {
    return this.libDateFormat.dateTimeToDate(date);
  }

  public differenceInMilliseconds(startDate: Date, endDate: Date): number {
    return this.libDateTimeDifference.differenceInMilliseconds(
      endDate,
      startDate
    );
  }
}
