import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

import { LibHttp, LibHttpOptionsSettings } from './models/lib-http.model';
import { LibHttpCallsService } from './services/lib-http-calls.service';

@Injectable({
  providedIn: 'root',
})
export class LibHttpService {
  private libApiDataHttp = inject(LibHttpCallsService);

  public http$<T>(
    httpData: LibHttp,
    options?: LibHttpOptionsSettings | null,
  ): Observable<T> {
    return this.libApiDataHttp.http$(httpData, options);
  }

  public httpResponse$<T>(
    httpData: LibHttp,
    options?: LibHttpOptionsSettings,
  ): Observable<HttpResponse<T>> {
    return this.libApiDataHttp.httpResponse$(httpData, options);
  }
}
