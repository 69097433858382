export enum LibApiDataConverterTypes {
  TO_CAMEL = 'to camel',
  UNDERSCORE_TO_CAMEL = 'underscore to camel',
  CAMEL_TO_UNDERSCORE = 'camel to underscore',
  NONE = 'none',
}

export interface LibApiDataOptions {
  readonly useUtc?: boolean;
  readonly noTimezoneDateConversion?: boolean;
  readonly noIsoConversion?: boolean;
  readonly dateOnly?: string[];
  readonly dateOnlyObjectKeys?: string[];
}