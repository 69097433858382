import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, catchError, map, of } from 'rxjs';
import { LibApiDataService } from '../../lib-api-data/lib-api-data.service';
import { LibApiDataConverterTypes } from '../../lib-api-data/models/lib-api-data.model';
import { LibDialogService } from '../../lib-dialog/lib-dialog.service';
import {
  LibHttp,
  LibHttpOptionsSettings,
  LibHttpTypes,
} from '../models/lib-http.model';
import { LibFrameworkDataService } from '../../lib-framework/services/lib-framework-data.service';

@Injectable({
  providedIn: 'root',
})
export class LibHttpCallsService {
  private http = inject(HttpClient);
  private libApiData = inject(LibApiDataService);
  private libDialog = inject(LibDialogService);
  private snackBar = inject(MatSnackBar);
  private $globalHttpOptionSetting = inject(LibFrameworkDataService)
    .$globalHttpOptionSetting;

  public http$<T>(
    httpData: LibHttp,
    options?: LibHttpOptionsSettings | null
  ): Observable<T> {
    const httpCall$ = this.generateHttp<T>(httpData, options || {});

    return httpCall$.pipe(
      map((data) => this.processData(data, options)),
      catchError((error: HttpErrorResponse) =>
        this.processError<T>(error, options)
      )
    );
  }

  public httpResponse$<T>(
    httpData: LibHttp,
    options?: LibHttpOptionsSettings
  ): Observable<HttpResponse<T>> {
    const httpCall$ = this.generateHttpResponse<T>(httpData, options || {});

    return httpCall$.pipe(
      map((response) => this.processResponseData<T>(response, options))
    );
  }

  private generateHttp<T>(
    httpData: LibHttp,
    options: LibHttpOptionsSettings
  ): Observable<T> {
    const data = this.libApiData.convert(
      httpData?.data,
      options?.dataConvertSubmit ||
        this.$globalHttpOptionSetting()?.dataConvertSubmit ||
        LibApiDataConverterTypes.NONE,
      options?.dataConvertOptionsSubmit ||
        this.$globalHttpOptionSetting()?.dataConvertOptionsSubmit
    );
    switch (httpData.type) {
      case LibHttpTypes.POST:
        return this.http.post<T>(httpData.url, data, httpData?.httpOptions);
      case LibHttpTypes.PUT:
        return this.http.put<T>(httpData.url, data, httpData?.httpOptions);
      case LibHttpTypes.PATCH:
        return this.http.patch<T>(httpData.url, data, httpData?.httpOptions);
      case LibHttpTypes.DELETE:
        return this.http.delete<T>(httpData.url, httpData?.httpOptions);
      default:
        return this.http.get<T>(httpData.url, httpData?.httpOptions);
    }
  }

  private generateHttpResponse<T>(
    httpData: LibHttp,
    options: LibHttpOptionsSettings
  ): Observable<HttpResponse<T>> {
    const data = this.libApiData.convert(
      httpData?.data,
      options?.dataConvertSubmit ||
        this.$globalHttpOptionSetting()?.dataConvertSubmit ||
        LibApiDataConverterTypes.NONE,
      options?.dataConvertOptionsSubmit ||
        this.$globalHttpOptionSetting()?.dataConvertOptionsSubmit
    );

    switch (httpData.type) {
      case LibHttpTypes.POST:
        return this.http.post<T>(httpData.url, data, {
          ...httpData?.httpOptions,
          observe: 'response',
        });
      case LibHttpTypes.PUT:
        return this.http.put<T>(httpData.url, data, {
          ...httpData?.httpOptions,
          observe: 'response',
        });
      case LibHttpTypes.PATCH:
        return this.http.patch<T>(httpData.url, data, {
          ...httpData?.httpOptions,
          observe: 'response',
        });
      case LibHttpTypes.DELETE:
        return this.http.delete<T>(httpData.url, {
          ...httpData?.httpOptions,
          observe: 'response',
        });
      default:
        return this.http.get<T>(httpData.url, {
          ...httpData?.httpOptions,
          observe: 'response',
        });
    }
  }

  private processData<T>(data: T, options?: LibHttpOptionsSettings | null): T {
    if (options?.snackBarMessage) {
      this.snackBar.open(options.snackBarMessage, 'Dismiss', {
        duration: 5000,
      });
    }

    return this.libApiData.convert<T>(
      data,
      options?.dataConvert ||
        this.$globalHttpOptionSetting()?.dataConvert ||
        LibApiDataConverterTypes.NONE,
      options?.dataConvertOptions ||
        this.$globalHttpOptionSetting()?.dataConvertOptions
    );
  }

  private processResponseData<T>(
    response: HttpResponse<T>,
    options?: LibHttpOptionsSettings
  ): HttpResponse<T> {
    if (options?.snackBarMessage) {
      this.snackBar.open(options.snackBarMessage, 'Dismiss', {
        duration: 5000,
      });
    }

    const cleanedData = this.libApiData.convert<T>(
      response.body,
      options?.dataConvert ||
        this.$globalHttpOptionSetting()?.dataConvert ||
        LibApiDataConverterTypes.NONE,
      options?.dataConvertOptions ||
        this.$globalHttpOptionSetting()?.dataConvertOptions
    );

    return { ...response, body: cleanedData } as HttpResponse<T>;
  }

  private processError<T>(
    error: HttpErrorResponse,
    options?: LibHttpOptionsSettings | null
  ): Observable<T> {
    const infoText = options?.requestInfo ? `${options?.requestInfo}: ` : '';
    const errorResponse = options?.fullErrorMessage
      ? error
      : new Error(error.statusText);

    let errorTitle: string | null = null;

    switch (error.status) {
      case 404:
        return this.notFoundError(errorResponse, options);
      case 401:
        errorTitle = 'Unauthorized';
        break;
      case 400:
        errorTitle = 'Validation';
        break;
      case 403:
        errorTitle = 'Forbidden';
        break;
      case 500:
        errorTitle = 'Internal Error';
        break;
    }

    const errorString = typeof error.error === 'string' ? error.error : null;

    if (!options?.noErrorPopup) {
      this.libDialog.error$(
        errorTitle || error.status.toString(),
        options?.defaultError || `${infoText}${errorString || error.statusText}`
      );
    }

    throw errorResponse;
  }

  private notFoundError<T>(
    errorResponse: Error | HttpErrorResponse,
    options?: LibHttpOptionsSettings | null
  ): Observable<T> {
    if (options && !(options.notFoundValue === undefined)) {
      return of(options.notFoundValue);
    } else {
      const infoText = options?.requestInfo ? `${options?.requestInfo} ` : '';
      this.snackBar.open(`${infoText} Not Found`, 'Dismiss', {
        duration: 5000,
      });
      throw errorResponse;
    }
  }
}
