import { Injectable, inject } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { LibStyleFrameworkClasses } from '../../../lib-styles/models/lib-styles-framework-enum.model';
import { LibDialogOptions } from '../../models/lib-dialog.model';
import { LibDialogOkComponent } from '../lib-dialog-ok.component';

@Injectable({
  providedIn: 'root',
})
export class LibDialogOkService {
  private dialog = inject(MatDialog);

  public ok$(
    title: string,
    message: string | string[],
    options: LibDialogOptions = {}
  ): Observable<boolean> {
    let dialogRef: MatDialogRef<LibDialogOkComponent>;
    let config: MatDialogConfig = { autoFocus: 'dialog' };

    config = options.height ? { ...config, height: options.height } : config;
    config = options.width ? { ...config, width: options.width } : config;

    dialogRef = this.dialog.open(LibDialogOkComponent, config);
    dialogRef.disableClose = options?.disableClose || false;
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = Array.isArray(message)
      ? message
      : [message ?? ''];
    dialogRef.componentInstance.options = options;

    return dialogRef.afterClosed();
  }

  public error$(
    title: string,
    message: string | string[],
    options: LibDialogOptions = {}
  ): Observable<boolean> {
    const errorOptions: LibDialogOptions = {
      ...options,
      titleClass: options.titleClass
        ? options.titleClass
        : LibStyleFrameworkClasses.LIB_WARN_COLOR,
    };
    return this.ok$(`Error: ${title}`, message, errorOptions);
  }
}
