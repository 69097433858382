import { Injectable, Signal, computed, inject } from '@angular/core';
import { LibFrameworkDataService } from '../../lib-framework/services/lib-framework-data.service';
import { LibStyleFrameworkClasses } from '../models/lib-styles-framework-enum.model';

@Injectable({
  providedIn: 'root',
})
export class LibStylesGreyDataService {
  private isDarkMode = inject(LibFrameworkDataService).$isDarkMode;

  public $greyColor: Signal<string> = computed(() =>
    this.isDarkMode()
      ? LibStyleFrameworkClasses.LIB_GREY_COLOR_DARK
      : LibStyleFrameworkClasses.LIB_GREY_COLOR
  );

  public $greyHover: Signal<string> = computed(() =>
    this.isDarkMode()
      ? LibStyleFrameworkClasses.LIB_GREY_HOVER_DARK
      : LibStyleFrameworkClasses.LIB_GREY_HOVER
  );
}
